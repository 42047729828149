import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';

import { AuthProvider, useAuth } from 'hooks';
import { ContainerDeMensagensFlutuantes } from '@digix-ui/componentes';
import { importarRota, RouterCreator } from 'router';
import { routesConfig } from 'config';
import { Permissoes } from 'enums/permissoes';
import { ReactNode } from 'react';

import { AppLayout, loader as appLoader } from 'layouts';
import ConteudoIndisponivel from 'components/ConteudoIndisponivel';
import Erro from 'pages/ConsultaAtendimento.fa.$id/Erro';

export function RotaPrivada({
  children,
  permissoes,
}: {
  children: ReactNode;
  permissoes: Permissoes[];
}) {
  const { usuario, podeAcessar } = useAuth();

  if (!usuario || !podeAcessar(permissoes, { temQualquerPermissao: true })) {
    return <Navigate to="/app/aplicativos" />;
  }

  return <>{children}</>;
}

const router = createBrowserRouter([
  {
    element: (
      <>
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </>
    ),
    children: [
      {
        element: <AppLayout />,
        loader: appLoader,
        children: [
          {
            path: '*',
            element: (
              <RouterCreator
                routesConfig={routesConfig}
                redirectPath="/app/aplicativos"
                fallbackRedirectPath="/"
              />
            ),
          },
          {
            path: '/app/configuracao/',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('Configuracao'),
            children: [
              {
                path: 'agendas',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracaoAgendas'),
              },
              {
                path: 'unidades',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracaoUnidades'),
              },
              {
                path: 'notificacoes',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracaoNotificacoes'),
              },
            ],
          },
          {
            path: '/app/configuracao/notificacoes/:id',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConfiguracaoNotificacoes.$ID'),
          },
          {
            path: '/app/configuracao/unidades/:id',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConfiguracaoUnidades.$ID'),
          },
          {
            path: '/app/configuracao/unidades/:id',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConfiguracaoUnidades.$ID'),
          },
          {
            path: '/app/configuracao/agendas/:id',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConfiguracoesDoSistema'),
            children: [
              {
                path: 'geral',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracoesDoSistema.Geral'),
              },
              {
                path: 'feriados',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracoesDoSistema.Feriados'),
              },
              {
                path: 'forca-tarefa',
                errorElement: <ConteudoIndisponivel />,
                lazy: importarRota('ConfiguracoesDoSistema.ForcaTarefa'),
              },
            ],
          },
          {
            path: '/app/consulta-atendimento',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConsultaAtendimento'),
            children: [
              {
                path: 'fa/:id',
                errorElement: <Erro />,
                lazy: importarRota('ConsultaAtendimento.fa.$id'),
              },
            ],
          },
          {
            path: '/app/configuracao/unidades/:idUnidade/areadeatendimento/:idArea?',
            errorElement: <ConteudoIndisponivel />,
            lazy: importarRota('ConfiguracaoAreaDeAtendimento'),
          },
        ],
      },
    ],
  },
]);

export default function App() {
  return (
    <>
      <ContainerDeMensagensFlutuantes />
      <RouterProvider router={router} />
    </>
  );
}
